






















































































































import { Component, Vue } from "vue-property-decorator";
import FailedPaymentsChart from "@/components/FailedPaymentsChart.vue";
import TestOrdersChart from "@/components/TestOrdersChart.vue";
import TotalPaymentsChart from "@/components/TotalPaymentsChart.vue";
import AdminDashboardChart from "@/components/AdminDashboardChart.vue";
import AdminDashboardNorthStar from "@/components/AdminDashboardNorthStar.vue";

@Component({
    components: {
        AdminDashboardNorthStar,
        AdminDashboardChart,
        TotalPaymentsChart,
        TestOrdersChart,
        FailedPaymentsChart,
    },
})
export default class AdminDashboardIndex extends Vue {}
